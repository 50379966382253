import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import webSocketService from "../helpers/utils/wss";

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [isConnected, setIsConnected] = useState(webSocketService.isConnected);

  const sendMessage = useCallback((message) => {
    webSocketService.sendMessage(message);
  }, []);

  const reconnect = useCallback(() => {
    webSocketService.connect();
  }, []);
  const close = useCallback(() => {
    webSocketService.close();
  }, []);

  useEffect(() => {
    webSocketService.onMessage((message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    webSocketService.setStatusChangeCallback((status) => {
      setIsConnected(status);
    });

    return () => {
      webSocketService.close();
    };
  }, []);

  const values = useMemo(
    () => ({
      messages,
      sendMessage,
      isConnected,
      reconnect,
      close,
    }),
    [messages, sendMessage, isConnected, reconnect, close]
  );

  return (
    <WebSocketContext.Provider value={values}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
