import { OPEN_MODAL } from "../actions";
import { AUCTIONS_EXAMPLE, AUCTION_DETAILS, AUCTION_BIDS, ACTIVE_PRODUCT, STATUS_TYPE, HIGHEST_BID, BID_INCREMENT, START_PRICE } from "./types";
export const addCompanies = (value) => {
  return (dispatch) => dispatch({
    type: AUCTIONS_EXAMPLE,
    payload: value
  })
}
export const setAuctionDetails = (payload) => {
  return {
    type: AUCTION_DETAILS,
    payload
  };
};

export const setAuctionBids = (payload) => {
  return {
    type: AUCTION_BIDS,
    payload
  };
};

export const setActiveProduct = (payload) => {
  return {
    type: ACTIVE_PRODUCT,
    payload
  }
}

export const setStatusType = (payload) => {
  return {
    type: STATUS_TYPE,
    payload
  }
}

export const setHighestBid = (payload) => {
  return {
    type: HIGHEST_BID,
    payload
  }
}

export const setBidIncrement = (payload) => {
  return {
    type: BID_INCREMENT,
    payload
  }
}
export const setStartPrice = (payload) => {
  return {
    type: START_PRICE,
    payload
  }
}