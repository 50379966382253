import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import auction from './auctions/reducer'
import auth from './auth/reducer'

const reducers = combineReducers({
  menu,
  settings,
  auction,
  auth
});

export default reducers;
