import { SET_NATIONAL_ID, SET_USAGE_MODAL, SET_OTP_RETRIEVED, SET_NAFATH_VERIFIED, SET_SELECTED_USER_TYPE, SET_OTP, SET_TRANS_ID, SET_INDIVIDUAL_USER_EXIST } from './type';
const initialState = {
    nationalId: "",
    usageModal: false,
    otpRetreived: false,
    nafathVerified: false,
    selectedUserType: "",
    otp: "",
    transId: "",
    individualUserExist: false
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NATIONAL_ID:
            return { ...state, nationalId: action.payload };
        case SET_USAGE_MODAL:
            return { ...state, usageModal: action.payload };
        case SET_OTP_RETRIEVED:
            return { ...state, otpRetreived: action.payload };
        case SET_NAFATH_VERIFIED:
            return { ...state, nafathVerified: action.payload };
        case SET_SELECTED_USER_TYPE:
            return { ...state, selectedUserType: action.payload };
        case SET_OTP:
            return { ...state, otp: action.payload };
        case SET_TRANS_ID:
            return { ...state, transId: action.payload };
        case SET_INDIVIDUAL_USER_EXIST:
            return { ...state, individualUserExist: action.payload };
        default:
            return state;
    }
};

export default authReducer;