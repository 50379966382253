/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "ar-SA";
export const localeOptions = [
  { id: "ar-SA", name: "العربية", direction: "rtl" },
  { id: "en-US", name: "English", direction: "ltr" },
];

export const themeColorStorageKey = "__theme_selected_color";
export const isMultiColorActive = true;
export const defaultColor = "light.blueolympic";
export const isDarkSwitchActive = true;
export const defaultDirection = "rtl";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
];
